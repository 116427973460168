var PharmacyAnalysisChart = (function () {
  function instantiate() {
    var me = new Object();
    me.options = {
      series: {
        // curvedLines: {
        //   active: true
        // }
      },
      yaxis: {
        zoomRange: false,
        panRange: false
      },
      xaxis: {
        zoomRange: [30, 90],
        panRange: [0, 90]
      },
      zoom: {
        interactive: true,
        amount:1.05
      },
      pan: {
        interactive: true,
      },
      grid:{
        hoverable:true
      }
    };
    me.dummy_data = function () {
      var d2 = [],
        bad_points = [],
        days = [];
      today = new Date();
      for (var day = 0; day <= 90; day++) {
        var v = Math.round(Math.random() * 140 + 1);
        if (v % 10 == 0) {
          v = 0;
        }

        var d = new Date(
          new Date().setDate(
            new Date().getDate() - (90 - day)
          )
        );
        d = String(d).split(' ').slice(0,4).join(' ');
        // console.log(d);

        d2.push([day, v]);
        days.push([day, d]);
      }
      for (var i = 0; i < d2.length; i++) {
        var result = null;
        if (d2[i][1] < 1) {
          result = 0;
        }
        bad_points[i] = [i, result];

      }
      me.data = d2;
      me.bad_points = bad_points;
      me.days = days;
    };
    me.setup_tooltips = function () {
      $('<div id=\'bad_tooltip\'></div>').css({
        position: 'absolute',
        display: 'none',
        border: '1px solid #fdd',
        padding: '2px',
        'background-color': '#fee',
        opacity: 0.80
      }).appendTo('body');

      $('<div id=\'tooltip\'></div>').css({
        position: 'absolute',
        display: 'none',
        border: '1px solid #ddf',
        padding: '2px',
        'background-color': '#eef',
        opacity: 0.80
      }).appendTo('body');

      $(me.selector).on('plothover', function (event, pos, item) {

        if (item) {

          var d = me.days[item.datapoint[0]][1];
          var y = item.datapoint[1].toFixed(0);
          var selector = '#tooltip';

          $(document).trigger('plot_point:highlighted', item.datapoint[0]);
          if (me.bad_points[item.datapoint[0]][1] === 0) {
            selector = '#bad_tooltip';
            $('#tooltip').hide();
          } else {
            $('#bad_tooltip').hide();
          }
          $(selector).html(y+ ' Records<br /> ' + d)
            .css({top: item.pageY+5, left: item.pageX+5})
            .fadeIn(200);
        } else {
          $(document).trigger('plot_point:unhighlighted');
          $('#tooltip').hide();
          $('#bad_tooltip').hide();
        }


      });
    };
    me.setup_external_controls = function () {
      $('*[data=chart-zoom-days]').on('change', function (event) {
        var axes = me.plot.getAxes(),
          xaxis = axes.xaxis.options,
          yaxis = axes.yaxis.options;
        xaxis.min = null;
        xaxis.max = null;
        yaxis.min = null;
        yaxis.max = null;


        var days = $(this).val(),
          zoom = 30;
        // console.log(days);
        if (days === '60 Days') {
          zoom = 60;
        }

        if (days === '90 Days') {
          zoom = 90;
        }

        xaxis.min = 0;
        xaxis.max = zoom;

        me.plot.setupGrid();
        me.plot.draw();

      });
    };
    me.regraph = function () {
      me.graph(me.selector);
    };
    me.graph = function (placeholder) {
      me.selector = placeholder;
      me.setup_tooltips();
      me.setup_external_controls();
      me.plot = $.plot($(placeholder), [
        {
          data: me.data,
          label: 'Dispensations',
          color: '#0000FF',
          lines: {
            show:true
          },
          points: {
            show: true
          }
        },
        {
          data: me.bad_points,
          color: '#FF0000',
          lines: {
            show:false
          },
          points: {
            show: true
          }
        }
      ], me.options);
      var axes = me.plot.getAxes(),
        xaxis = axes.xaxis.options,
        yaxis = axes.yaxis.options;
      xaxis.min = 0;
      xaxis.max = 30;
      yaxis.min = null;
      yaxis.max = null;

      me.plot.setupGrid();
      me.plot.draw();
    };
    me.update_chart_with_json = function (json) {
      // console.log(json);
      var points = [];
      var bad = [];
      var days = [];
      for (var i = 0; i < json.length; i++) {
        var row = json[i];
        var r = row.prescription_count;
        points.push([i, r]);

        var d = null;
        if (row.submission_type.toLowerCase() === 'delinquent') {
          d = 0;
        }
        bad.push([i, d]);

        var day = row.submitted_on;
        days.push([i, day]);
      }

      me.data = points;
      me.bad_points = bad;
      me.days = days;
      me.regraph();
      $('*[data=chart-zoom-days]').trigger('change');
      // console.log(points);
      // me.plot.setData(points);
      // me.plot.setupGrid();
      // me.plot.draw();

    };
    $( document ).ajaxComplete(function( event, xhr, settings ) {
      if ( settings.url.match('pharmacies/analysis_chart')) {
        me.update_chart_with_json(JSON.parse(xhr.responseText).pharmacies);
      }
    });

    // $(document).on("ready", function () {
    //   $('form#form_chart').on('ajaxSuccess', function (data) {
    //     alert("ohai");
    //     console.log(data);
    //   });
    // });

    return me;
  }
  var obj = new Object();
  obj.instantiate = instantiate;
  return obj;
})();

let chart = null;

$(function () {
  chart = PharmacyAnalysisChart.instantiate();
  var chart_placeholder = $('#chart');
  if (chart_placeholder.length > 0) {
    // chart.dummy_data();
    chart.graph('#chart');
    $('form#form_chart').trigger('submit');
  }
});
