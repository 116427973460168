(function(submittersCommunicationOnAppLoad) {
  submittersCommunicationOnAppLoad(window.jQuery, window, document);
}(function($, window, document) {
  $(function() {
    $('.select2-js').select2({ theme: 'bootstrap4', templateResult: formatState });
    $('#submitters-communications-attachment-link').on('click', triggerFileInput);
    $('form').on('change', '#submitters-communication-upload-file-input-js', uploadsubmittersCommunicationFile);
    $('.submit-submitters-communication-js').on('click', addSelec2tValidationForIE11);
    
  });

  function triggerFileInput(e) {
    e.preventDefault();
    $('#submitters-communication-upload-file-input-js').click();
  }

  function formatState(status) {
    if (!status.id || status.title == 'status') {
      return status.text;
    }
    var $status = $(
      '<span>' +
      status.text +
      ' - ' +
      status.element.dataset.email +
      '<span class="submitter-icon '+ status.element.dataset.status +'"></span>' +
      '</span>'
    );
    return $status;
  }

  function addSelec2tValidationForIE11() {
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      var $select2 = $('.select2-js');

      if ($select2.val() == null) {
        var $select = $('.select2-container');

        $select.css({'border': '3px solid red'});
        $select2.on('select2:select', function(e) {
          $select.css({'border': ''});
        });
      }
    }
  }

  function uploadsubmittersCommunicationFile() {
    var form = $('#submitters-communication-upload-form-js');
    var formData = new FormData();
    var fileInput = $('#submitters-communication-upload-file-input-js')[0];
    var removeAttachmentBtn = $('#remove-attachment-btn');
    var fileNameDisplay = $('#file-name');
    var fileName = fileInput.files[0].name;

    if (fileInput && fileInput.files.length > 0) {
      formData.append('attachment', fileInput.files[0]);
    } else {
      console.log('No file selected or element not found');
    }
    $.ajax({
      type: 'POST',
      url: form.attr('action'),
      data: formData,
      processData: false,
      contentType: false,
      beforeSend: function() {
        $('#submitters-communication-upload-file-input-js').attr('disabled', true);
        $('#case-document-upload-gif').removeClass('hidden');
      },
      complete: function() {
        $('#submitters-communication-upload-file-input-js').attr('disabled', false);
        $('#case-document-upload-gif').addClass('hidden');
        $('#submitters-communication-upload-file-input-js').prop('value', '');
        removeAttachmentBtn.removeClass('hidden');
        fileNameDisplay.text(fileName);
        fileNameDisplay.removeClass('hidden');
      }
    });

    $('#submitters-communication-upload-file-input-js').on('change', function() {
      if (fileInput.files.length > 0) {
        removeAttachmentBtn.removeClass('hidden');
        fileNameDisplay.text(fileName);
        fileNameDisplay.removeClass('hidden');
      } else {
        removeAttachmentBtn.addClass('hidden');
        fileNameDisplay.addClass('hidden');
        fileNameDisplay.text('');
      }
    });

    removeAttachmentBtn.on('click', function(e) {
      e.preventDefault();
      $('#submitters-communication-upload-file-input-js').val('');
      removeAttachmentBtn.addClass('hidden');
      fileNameDisplay.text('');
    });
  }
}));
